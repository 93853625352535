<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <div class="card">
        <Toast />
        <Toolbar class="p-mb-4" v-if="!loadingGetStaffs && !loadingGetOutlets">
          <template v-slot:left>
            <Button
              label="New Staff"
              icon="pi pi-plus"
              class="p-button-success p-mr-2 p-mb*2"
              @click="openNew"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dtStaffs"
          :value="staffs"
          class="p-datatable-responsive-demo"
          dataKey="id"
          :paginator="true"
          :rows="4"
          :lazy="true"
          @page="onPage($event)"
          @sort="onSort($event)"
          :totalRecords="totalStaffs"
          :filters="filters"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[4 ,5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} staffs"
          :loading="loadingGetStaffs || loadingGetOutlets"
          stripedRows
        >
          <template #header>
            <div class="table-header">
              <h5 class="p-m-0">Manage Staffs</h5>
              <div class="table-header-container">
                <span class="p-input-icon-left" v-if="!loadingGetOutlets">
                  <Dropdown
                    id="searchFilterbyOutlet"
                    v-model="searchFilterbyOutlet"
                    :options="searchFilterbyOutletOptions"
                    optionLabel="Choose filter"
                    placeholder="Select a filter"
                    style="width: 150px"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>
                          {{ slotProps.value.name }}
                        </div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>
                          {{ slotProps.option.name }}
                        </div>
                      </div>
                    </template>
                  </Dropdown>

                  <InputMask
                    id="staffContact"
                    placeholder="Search by Contact"
                    v-model.trim="searchFilterbyContact"
                    mask="999-999 9999?9"
                  />
                </span>

                <Button
                  label="Search"
                  class="p-button"
                  @click="loadLazyData()"
                />
              </div>
            </div>
          </template>

          <Column field="staffId" header="Staff ID">
            <template #body="slotProps">
              {{ slotProps.data.staffId }}
            </template>
          </Column>
          <Column field="primeUser.userName" header="UserName">
            <template #body="slotProps">
              {{ slotProps.data.primeUser.userName }}
            </template>
          </Column>
          <Column field="primeUser.email" header="Email">
            <template #body="slotProps">
              <!-- <span class="p-column-title">Phone</span> -->
              {{ slotProps.data.primeUser.email }}
            </template>
          </Column>
          <Column field="primeUser.phone" header="Phone">
            <template #body="slotProps">
              <!-- <span class="p-column-title">Phone</span> -->
              {{ slotProps.data.primeUser.phone }}
            </template>
          </Column>
          <Column field="staffPosition.name" header="Position">
            <template #body="slotProps">
              <!-- <span class="p-column-title">Position</span> -->
              {{ slotProps.data.staffPosition.name }}
            </template>
          </Column>
          <Column field="outlet.name" header="Outlet">
            <template #body="slotProps">
              <!-- <span class="p-column-title">Position</span> -->
              {{ slotProps.data.outlet.name }}
            </template>
          </Column>
          <Column
            field="counter.counterToCounterGroupAdjustmentLogs.counterGroup.name"
            header="Group"
          >
            <template #body="slotProps">
              <!-- <span class="p-column-title">Position</span> -->
              <!-- {{
                slotProps.data.hasCounter
                  ? slotProps.data.counter.counterGroup.name
                  : "N/A"
              }} -->
              <span v-if="slotProps.data.hasCounter">
                <span
                  v-if="
                    slotProps.data.counter.counterToCounterGroupAdjustmentLogs
                      .length > 0
                  "
                >
                  <span
                    v-for="counterToCounterGroupAdjustmentLog in slotProps.data
                      .counter.counterToCounterGroupAdjustmentLogs"
                    v-bind:key="counterToCounterGroupAdjustmentLog.id"
                  >
                    {{ counterToCounterGroupAdjustmentLog.counterGroup.name }}
                  </span>
                </span>
                <span style="color: red" v-else>NEED A GROUP!</span>
              </span>
              <span v-else> N/A </span>
            </template>
          </Column>
          <Column field="counter.name" header="Counter">
            <template #body="slotProps">
              <!-- <span class="p-column-title">Position</span> -->
              {{
                slotProps.data.hasCounter
                  ? 'Counter ' + slotProps.data.counter.number
                  : 'N/A'
              }}
            </template>
          </Column>

          <Column field="dateCreated" header="Date Created">
            <template #body="slotProps">
              {{ dateDisplayFormat(slotProps.data.createdAt) }}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="editStaff(slotProps.data)"
              />
              <!-- <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                @click="confirmDeleteStaff(slotProps.data)"
              /> -->
            </template>
          </Column>
          <template #empty> No records found. </template>
          <template #loading> Loading staffs data. Please wait. </template>
        </DataTable>

        <Dialog
          v-model:visible="staffDialog"
          :style="{ width: '450px' }"
          header="Staff Details"
          :modal="true"
          class="p-fluid"
          ><div class="p-field">
            <label for="staffId">Staff ID</label>
            <InputText
              id="staffId"
              v-model.trim="staff.staffId"
              :disabled="staff.id"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !staff.staffId }"
            />
            <small class="p-invalid" v-if="submitted && !staff.staffId"
              >Staff ID is required.</small
            >
          </div>
          <div class="p-field">
            <label for="firstName">First Name</label>
            <InputText
              id="firstName"
              v-model.trim="staff.primeUser.firstName"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !staff.primeUser.firstName }"
            />
            <small
              class="p-invalid"
              v-if="submitted && !staff.primeUser.firstName"
              >First name is required.</small
            >
          </div>

          <div class="p-field">
            <label for="lastName">Last Name</label>
            <InputText
              id="lastName"
              v-model.trim="staff.primeUser.lastName"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !staff.primeUser.lastName }"
            />
            <small
              class="p-invalid"
              v-if="submitted && !staff.primeUser.lastName"
              >Last name is required.</small
            >
          </div>
          <div class="p-field">
            <label for="UserName">UserName</label>
            <InputText
              id="UserName"
              v-model.trim="staff.primeUser.userName"
              :disabled="staff.id"
              required="true"
              autofocus
              :class="{
                'p-invalid':
                  submitted &&
                  (!staff.primeUser.userName ||
                    hasWhiteSpace(staff.primeUser.userName)),
              }"
            />
            <small
              class="p-invalid"
              v-if="
                submitted &&
                (!staff.primeUser.userName ||
                  hasWhiteSpace(staff.primeUser.userName))
              "
              >Username is required. Please insert a valid username without
              spaces.</small
            >
          </div>

          <div class="p-field">
            <label for="staffContact">Contact Number</label>
            <InputMask
              id="staffContact"
              v-model.trim="staff.primeUser.phone"
              :class="{
                'p-invalid': submitted && !staff.primeUser.phone,
              }"
              mask="999-999 9999?9"
            />

            <!-- mask="(999) 999-9999?9" -->
            <small class="p-invalid" v-if="submitted && !staff.primeUser.phone"
              >Contact number is required.</small
            >
          </div>

          <div class="p-field">
            <label for="email">Email</label>
            <InputText
              type="email"
              id="email"
              v-model.trim="staff.primeUser.email"
              :disabled="staff.id"
              required="true"
              autofocus
              :class="{
                'p-invalid':
                  submitted &&
                  (!staff.primeUser.email ||
                    !staff.primeUser.email.includes('@') ||
                    !staff.primeUser.email.includes('.com')),
              }"
            />
            <small
              class="p-invalid"
              v-if="
                submitted &&
                (!staff.primeUser.email ||
                  !staff.primeUser.email.includes('@') ||
                  !staff.primeUser.email.includes('.com'))
              "
              >Email is required. Please insert a valid email with '@' and
              '.com'.</small
            >
          </div>
          <div class="p-field">
            <label for="email">Outlet</label>
            <Dropdown
              id="outlet"
              v-model="staff.outlet"
              :options="outlets"
              optionLabel="outlet"
              placeholder="Select an outlet"
              @click="hasNoCounter()"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="p-field">
            <label class="p-mb-3">Position</label>
            <div class="p-formgrid p-grid">
              <div
                v-for="staffPosition in filteredStaffPositions"
                v-bind:key="staffPosition.id"
                class="p-field-radiobutton p-col-6"
              >
                <RadioButton
                  :id="staffPosition.name"
                  :name="staffPosition.name"
                  :value="staffPosition"
                  v-model="staff.staffPosition"
                  @click="hasNoCounter()"
                />
                <label :for="staffPosition.name">{{
                  staffPosition.name
                }}</label>
              </div>
              <!-- <div class="p-field-radiobutton p-col-6">
                <RadioButton
                  id="position2"
                  name="staffPosition"
                  value="Warehouse"
                  v-model="staff.staffPosition.name"
                />
                <label for="position1">Warehouse</label>
              </div>
              <div class="p-field-radiobutton p-col-6">
                <RadioButton
                  id="position3"
                  name="staffPosition"
                  value="Salesperson"
                  v-model="staff.staffPosition.name"
                />
                <label for="position2">Salesperson</label>
              </div>
              <div class="p-field-radiobutton p-col-6">
                <RadioButton
                  id="position4"
                  name="staffPosition"
                  value="Technician"
                  v-model="staff.staffPosition.name"
                />
                <label for="position3">Technician</label>
              </div> -->
            </div>

            <small
              class="p-invalid"
              v-if="submitted && !staff.staffPosition.name"
              >Position is required.</small
            >
          </div>
          <div class="p-field">
            <label for="password">Password</label>
            <InputText
              id="password"
              v-model="password"
              required="true"
              :class="{ 'p-invalid': submitted && !password }"
              autofocus
              disabled
            ></InputText>
            <small class="p-invalid" v-if="submitted && !password"
              >Password is required.</small
            >
          </div>
          <div class="p-field" v-if="staff.outlet && activatedByPosition">
            <label for="counter">Has Counter?</label><br />
            <InputSwitch
              v-model="staff.hasCounter"
              :onchange="setCounterDetails()"
            />
          </div>
          <div
            class="spinner-border text-primary"
            v-if="staff.hasCounter && loadingGetCounters && counters == null"
          ></div>
          <div
            class="p-field"
            v-else-if="
              staff.hasCounter && !loadingGetCounters && counters != null
            "
          >
            <label for="counter.number">Counter Number</label>
            <Dropdown
              id="counter.number"
              v-model="staff.counter"
              :options="countersOptionFilteredForStaff"
              optionLabel="Counters"
              placeholder="Select a Counter"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value.number"
                >
                  <div>
                    Counter {{ slotProps.value.number }} [{{
                      slotProps.value.outlet.name
                    }}]
                    <span
                      v-for="counterToCounterGroupAdjustmentLog in slotProps
                        .value.counterToCounterGroupAdjustmentLogs"
                      v-bind:key="counterToCounterGroupAdjustmentLog.id"
                    >
                      [{{
                        counterToCounterGroupAdjustmentLog.counterGroup.name
                      }}]
                    </span>
                  </div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>
                    Counter {{ slotProps.option.number }} [{{
                      slotProps.option.outlet.name
                    }}]
                    <span
                      v-for="counterToCounterGroupAdjustmentLog in slotProps
                        .option.counterToCounterGroupAdjustmentLogs"
                      v-bind:key="counterToCounterGroupAdjustmentLog.id"
                    >
                      [{{
                        counterToCounterGroupAdjustmentLog.counterGroup.name
                      }}]
                    </span>
                  </div>
                </div>
              </template>
            </Dropdown>

            <small
              class="p-invalid"
              v-if="submitted && staff.hasCounter && !staff.counter.number"
              >If the staff has counter, then counter number is required.</small
            >
          </div>
          <!-- <div class="p-field" v-if="staff.hasCounter">
            <label for="counterGroup">Counter Group</label>
            <Dropdown
              id="counterGroup"
              v-model="computedCounterGroup"
              :options="counterGroupOptions"
              optionLabel="Groups"
              placeholder="Select a Group"
              disabled
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.name"
                >
                  <div>{{ slotProps.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>

            <small
              class="p-invalid"
              v-if="submitted && staff.hasCounter && !staff.counterGroup.name"
              >If the staff has counter, then counter counterGroup is required.</small
            >
          </div> -->

          <error v-if="errorStaff" :error="error" />
          <template #footer>
            <div
              class="spinner-border text-primary"
              v-if="loadingPostStaff"
            ></div>
            <Button
              label="Cancel"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialog"
            />
            <Button
              :disabled="loadingPostStaff"
              label="Save"
              icon="pi pi-check"
              class="p-button-text"
              @click="saveStaff"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteStaffDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="staff"
              >Are you sure you want to delete <b>{{ staff.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteStaffDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteStaff"
            />
          </template>
        </Dialog>
      </div>
      <div class="card">
        <Toast />
        <Toolbar class="p-mb-4" v-if="!loadingGetCounterGroups">
          <template v-slot:left>
            <Button
              label="New Group"
              icon="pi pi-plus"
              class="p-button-success p-mr-2 p-mb*2"
              @click="openNewGroup"
            />
          </template>
        </Toolbar>
        <DataTable
          ref="dtCounterGroups"
          :value="counterGroups"
          class="p-datatable-responsive-demo"
          dataKey="id"
          :paginator="true"
          :rows="4"
          :lazy="true"
          @page="onPageCounterGroups($event)"
          @sort="onSortCounterGroups($event)"
          :totalRecords="totalCounterGroups"
          :filters="filters"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[4, 5, 10]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} counterGroups"
          :loading="loadingGetCounterGroups"
          stripedRows
        >
          <template #header>
            <div class="table-header">
              <h5 class="p-m-0">Manage Groups</h5>
              
              <div class="table-header-container">
                <span class="p-input-icon-left" v-if="!loadingGetOutlets">
                  <Dropdown
                    id="searchFilterGroupbyOutlet"
                    v-model="searchFilterGroupbyOutlet"
                    :options="searchFilterbyOutletOptions"
                    optionLabel="Choose filter"
                    placeholder="Select a filter"
                    style="width: 150px"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>
                          {{ slotProps.value.name }}
                        </div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>
                          {{ slotProps.option.name }}
                        </div>
                      </div>
                    </template>
                  </Dropdown></span></div>
              <!-- <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global']"
                  placeholder="Search..."
                />
              </span> -->
            </div>
          </template>

          <!-- <Column field="index" header="NO.">
            <template #body="slotProps">
              {{ slotProps.index + 1 }}
            </template>
          </Column> -->
          <Column field="name" header="Name">
            <template #body="slotProps">
              {{ slotProps.data.name }}
            </template>
          </Column>
          <Column field="outlet.name" header="Outlet">
            <template #body="slotProps">
              {{ slotProps.data.outlet.name }}
            </template>
          </Column>
          <Column field="services" header="Services">
            <template #body="slotProps">
              <span
                v-for="(
                  counterGroupToWalkinServiceTypeAdjustmentLog, index
                ) in slotProps.data
                  .counterGroupToWalkinServiceTypeAdjustmentLogs"
                v-bind:key="counterGroupToWalkinServiceTypeAdjustmentLog.id"
              >
                {{
                  counterGroupToWalkinServiceTypeAdjustmentLog.walkinServiceType
                    .name
                }}{{
                  index !=
                  slotProps.data.counterGroupToWalkinServiceTypeAdjustmentLogs
                    .length -
                    1
                    ? ', '
                    : null
                }}
              </span>
            </template>
          </Column>
          <Column field="counters" header="Counters">
            <template #body="slotProps">
              <span
                v-for="(counterToCounterGroupAdjustmentLog, index) in slotProps
                  .data.counterToCounterGroupAdjustmentLogs"
                v-bind:key="counterToCounterGroupAdjustmentLog.id"
              >
                {{ counterToCounterGroupAdjustmentLog.counter.number
                }}{{
                  index !=
                  slotProps.data.counterToCounterGroupAdjustmentLogs.length - 1
                    ? ', '
                    : null
                }}
              </span>
            </template>
          </Column>
          <Column field="dateCreated" header="Date Created">
            <template #body="slotProps">
              {{ dateDisplayFormat(slotProps.data.createdAt) }}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="editGroup(slotProps.data)"
              />
              <!-- <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                @click="confirmDeleteStaff(slotProps.data)"
              /> -->
            </template>
          </Column>
          <template #empty> No records found. </template>
          <template #loading>
            Loading counterGroups data. Please wait.
          </template>
        </DataTable>

        <Dialog
          v-model:visible="counterGroupDialog"
          :style="{ width: '450px' }"
          header="Group Details"
          :modal="true"
          class="p-fluid"
        >
          <div class="p-field">
            <label for="name">Name</label>
            <InputText
              id="name"
              v-model.trim="counterGroup.name"
              required="true"
              autofocus
              :class="{ 'p-invalid': submittedGroup && !counterGroup.name }"
            />
            <small class="p-invalid" v-if="submittedGroup && !counterGroup.name"
              >Group name is required.</small
            >
          </div>
          <div class="p-field">
            <label for="outlet">Outlet</label>
            <Dropdown
              id="outlet"
              v-model="counterGroup.outlet"
              :options="outlets"
              optionLabel="outlet"
              placeholder="Select an outlet"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
            <small
              class="p-invalid"
              v-if="submittedGroup && !counterGroup.outlet"
              >Group outlet is required.</small
            >
          </div>
          <div class="p-field" v-if="counterGroup.outlet">
            <label>Services</label><br />
            <small
              >(Please ensure that <b>EACH</b> services in any outlet just
              served by <b>ONE</b> group only)</small
            >
            <span class="p-fluid">
              <MultiSelect
                v-model="counterGroup.walkinServiceTypes"
                :options="walkinServiceTypesOptionFilteredForCounterGroup"
                optionLabel="name"
                :filter="true"
                placeholder="Select Services"
                class="multiselect-custom"
              >
                <template #value="slotProps">
                  <div v-for="option of slotProps.value" :key="option.id">
                    <div>{{ option.name }}</div>
                  </div>
                  <div v-if="!slotProps.value || slotProps.value.length === 0">
                    <div>Select Services</div>
                  </div>
                </template>
                <template #option="slotProps">
                  <div>
                    <div>{{ slotProps.option.name }}</div>
                  </div>
                </template>
              </MultiSelect>
            </span>
            <small
              class="p-invalid"
              v-if="
                submittedGroup && counterGroup.walkinServiceTypes.length == 0
              "
              >Group services is required.</small
            >
          </div>

          <div
            class="spinner-border text-primary"
            v-if="counterGroup.outlet && loadingGetCounters && counters == null"
          ></div>
          <div
            class="p-field"
            v-else-if="
              counterGroup.outlet && !loadingGetCounters && counters != null
            "
          >
            <label for="counters">Counters</label>
            <span class="p-fluid">
              <MultiSelect
                v-model="counterGroup.counters"
                :options="countersOptionFilteredForCounterGroup"
                optionLabel="counters"
                :filter="true"
                placeholder="Select Counters"
                class="multiselect-custom"
              >
                <template #value="slotProps">
                  <div v-for="option of slotProps.value" :key="option.id">
                    <div>
                      Counter {{ option.number }} [{{ option.outlet.name }}]
                    </div>
                  </div>
                  <div v-if="!slotProps.value || slotProps.value.length === 0">
                    <div>Select Counters</div>
                  </div>
                </template>
                <template #option="slotProps">
                  <div>
                    <div>
                      Counter {{ slotProps.option.number }} [{{
                        slotProps.option.outlet.name
                      }}]
                    </div>
                  </div>
                </template>
              </MultiSelect>
            </span>
            <small
              class="p-invalid"
              v-if="submittedGroup && counterGroup.counters.length == 0"
              >Counter is required.</small
            >
          </div>
          <div class="p-field">
            <label for="counter">Active</label><br />
            <InputSwitch v-model="counterGroup.isActive" />
          </div>
          <error v-if="errorGroup" :error="error" />
          <template #footer>
            <div
              class="spinner-border text-primary"
              v-if="loadingPostGroup"
            ></div>
            <Button
              label="Cancel"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialogGroup"
            />
            <Button
              :disabled="loadingPostGroup"
              label="Save"
              icon="pi pi-check"
              class="p-button-text"
              @click="saveGroup"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteGroupDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="counterGroup"
              >Are you sure you want to delete <b>{{ counterGroup.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteGroupDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteGroup"
            />
          </template>
        </Dialog>
      </div>
      <div class="card">
        <Toast />
        <Toolbar class="p-mb-4" v-if="!loadingGetOutlets">
          <template v-slot:left>
            <Button
              label="New Outlet"
              icon="pi pi-plus"
              class="p-button-success p-mr-2 p-mb*2"
              @click="openNewOutlet"
            />
          </template>
        </Toolbar>
        <DataTable
          ref="dtOutlets"
          :value="outlets"
          class="p-datatable-responsive-demo"
          dataKey="id"
          :paginator="true"
          :rows="5"
          :filters="filters"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} counterGroups"
          :loading="loadingGetOutlets"
          stripedRows
        >
          <template #header>
            <div class="table-header">
              <h5 class="p-m-0">Manage Outlets</h5>
              <!-- <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global']"
                  placeholder="Search..."
                />
              </span> -->
            </div>
          </template>

          <!-- <Column field="index" header="NO.">
            <template #body="slotProps">
              {{ slotProps.index + 1 }}
            </template>
          </Column> -->
          <Column field="organization.name" header="Organization">
            <template #body="slotProps">
              {{ slotProps.data.organization.name }}
            </template>
          </Column>
          <Column field="name" header="Name">
            <template #body="slotProps">
              {{ slotProps.data.name }}
            </template>
          </Column>
          <Column field="totalCounters" header="Number of Counters">
            <template #body="slotProps">
              {{ slotProps.data.counters.length }}
            </template>
          </Column>
          <Column field="dateCreated" header="Date Created">
            <template #body="slotProps">
              {{ dateDisplayFormat(slotProps.data.createdAt) }}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="editOutlet(slotProps.data)"
              />
              <!-- <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                @click="confirmDeleteStaff(slotProps.data)"
              /> -->
            </template>
          </Column>
          <template #empty> No records found. </template>
          <template #loading>
            Loading counterGroups data. Please wait.
          </template>
        </DataTable>

        <Dialog
          v-model:visible="outletDialog"
          :style="{ width: '450px' }"
          header="Outlet Details"
          :modal="true"
          class="p-fluid"
        >
          <div class="p-field">
            <label for="organization">Organization</label>
            <Dropdown
              id="organization"
              v-model="outlet.organization"
              :options="organizations"
              optionLabel="organization"
              placeholder="Select an organization"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
            <small
              class="p-invalid"
              v-if="submittedOutlet && !outlet.organization"
              >Organization is required.</small
            >
          </div>
          <div class="p-field">
            <label for="name">Name</label>
            <InputText
              id="name"
              v-model.trim="outlet.name"
              required="true"
              autofocus
              :class="{ 'p-invalid': submittedOutlet && !outlet.name }"
            />
            <small class="p-invalid" v-if="submittedOutlet && !outlet.name"
              >Outlet name is required.</small
            >
          </div>
          <div class="p-field">
            <label for="region">Region</label>
            <Dropdown
              id="region"
              v-model="outlet.region"
              :options="regions"
              optionLabel="region"
              placeholder="Select a region"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
            <small
              class="p-invalid"
              v-if="submittedOutlet && !outlet.regionId"
              >Region is required.</small
            >
          </div>
          <div class="p-field">
            <label for="totalCounters">Number of Counters</label>
            <InputNumber
              v-model="outlet.totalCounters"
              mode="decimal"
              showButtons
              buttonLayout="vertical"
              decrementButtonClass="p-button-secondary"
              incrementButtonClass="p-button-secondary"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
            />
            <small
              class="p-invalid"
              v-if="submittedOutlet && outlet.totalCounters == null"
              >Outlet counter is required.</small
            >
          </div>

          <div class="p-field">
            <label for="active">Active</label><br />
            <InputSwitch v-model="outlet.isActive" />
          </div>

          <div class="p-field">
            <label for="HQ">HQ</label><br />
            <InputSwitch v-model="outlet.isHQ" />
          </div>
          <error v-if="errorOutlet" :error="error" />
          <template #footer>
            <div
              class="spinner-border text-primary"
              v-if="loadingPostOutlet"
            ></div>
            <Button
              label="Cancel"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialogOutlet"
            />
            <Button
              :disabled="loadingPostOutlet"
              label="Save"
              icon="pi pi-check"
              class="p-button-text"
              @click="saveOutlet"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteOutletDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="outlet"
              >Are you sure you want to delete <b>{{ outlet.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteOutletDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteOutlet"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
// import StaffService from "../service/StaffService";

import Error from '../components/Error';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';

import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import RadioButton from 'primevue/radiobutton';
import Toast from 'primevue/toast';
import Toolbar from 'primevue/toolbar';

export let region = Object.freeze({
  Central: 1,
  Northern: 2,
  Southern: 3,
  EastCoast: 4,
  Borneo: 5,
});

export default {
  data() {
    return {
      // staffs: null,
      loadingGetStaffs: true,
      loadingGetCounters: false,
      loadingGetCounterGroups: true,
      loadingGetOutlets: true,
      loadingPostStaff: false,
      loadingPostGroup: false,
      loadingPostOutlet: false,
      staffDialog: false,
      counterGroupDialog: false,
      outletDialog: false,
      deleteStaffDialog: false,
      deleteGroupDialog: false,
      deleteOutletDialog: false,
      deleteStaffsDialog: false,
      staff: { outlet: null, hasCounter: false },
      counterGroup: { outlet: null },
      outlet: { counters: [] },
      errorStaff: false,
      errorGroup: false,
      errorOutlet: false,
      error: null,
      counter: {},
      searchFilterbyContact: '',
      searchFilterbyOutlet: { id: 0, name: 'All' },
      searchFilterGroupbyOutlet: { id: 0, name: 'All' },
      // searchFilterbyOutletOptions: [1, 2, 3, 4],
      // outlets: [
      //   { name: "IMIKA HQ" },
      //   { name: "IMIKA Ipoh" },
      //   { name: "IMIKA Manjung" },
      // ],
      // counterOptions: [
      //   { number: 1 },
      //   { number: 2 },
      //   { number: 3 },
      //   { number: 4 },
      //   { number: 5 },
      //   { number: 6 },
      //   { number: 7 },
      //   { number: 8 },
      //   { number: 9 },
      //   { number: 10 },
      //   { number: 11 },
      //   { number: 12 },
      //   { number: 13 },
      //   { number: 14 },
      //   { number: 15 },
      // ],
      counterGroupOptions: [
        { name: 'Group 1 (Computer Purchasing)' },
        { name: 'Group 2 (Computer Repairing)' },
        { name: 'Group 3 ((Phone Purchasing & Others)' },
        { name: 'Group 4 (Phone Repairing)' },
      ],
      selectedStaffs: null,
      filters: {},
      submitted: false,
      submittedGroup: false,
      submittedOutlet: false,
      hasCounter: false,
      lazyParams: {},
      lazyParamsCounterGroups: {},
    };
  },
  components: {
    Error,
    Column,
    DataTable,
    Dialog,
    Dropdown,
    InputMask,
    InputNumber,
    InputSwitch,
    InputText,
    MultiSelect,
    RadioButton,
    Toast,
    Toolbar,
  },
  watch: {
    outletOfStaff: async function (val) {
      if (val != null) {
        let self = this;
        this.loadingGetCounters = true;
        await self.$store
          .dispatch('staff/getCounters', { id: val.id })
          .then(() => {
            this.loadingGetCounters = false;
          });
      }
    },
    outletOfCounterGroup: async function (val) {
      if (val != null) {
        let self = this;
        this.loadingGetCounters = true;
        await self.$store
          .dispatch('staff/getCounters', { id: val.id })
          .then(() => {
            this.loadingGetCounters = false;
          });
      }
    },
    searchFilterGroupbyOutlet: function (val) {
        this.loadLazyDataCounterGroups();
    },
  },
  mounted() {
    let self = this;
    // self.$store.dispatch("staff/getStaffs").then(() => {
    //   this.loadingGetStaffs = false;
    // });

    this.lazyParams = {
      first: 0,
      rows: this.$refs.dtStaffs.rows,
      sortField: null,
      sortOrder: null,
      ref: 'dtStaffs',
    };

    this.lazyParamsCounterGroups = {
      first: 0,
      rows: this.$refs.dtCounterGroups.rows,
      sortField: null,
      sortOrder: null,
      ref: 'dtCounterGroups',
    };

    this.loadLazyData();

    this.loadLazyDataCounterGroups();

    // self.$store.dispatch("staff/getCounterGroups").then(() => {
    //   this.loadingGetCounterGroups = false;
    // });
    self.$store.dispatch('staff/getOutlets').then(() => {
      this.loadingGetOutlets = false;
    });
    self.$store.dispatch('staff/getOrganizations');
    self.$store.dispatch('staff/getWalkinServiceTypes');
    // .then(() => {console.log(this.walkinServiceTypes);
    // });
    self.$store.dispatch('staff/getStaffPositions');
  },
  computed: {
    filteredStaffPositions() {
      return this.staffPositions.filter((x) => x.isExclusive == false);
    },
    outletOfStaff() {
      return this.staff.outlet;
    },
    outletOfCounterGroup() {
      return this.counterGroup.outlet;
    },
    staffs() {
      return this.$store.getters['staff/staffs'];
    },
    totalStaffs() {
      return this.$store.getters['staff/totalStaffs'];
    },
    totalCounterGroups() {
      return this.$store.getters['staff/totalCounterGroups'];
    },
    counterGroups() {
      return this.$store.getters['staff/counterGroups'];
    },
    outlets() {
      return this.$store.getters['staff/outlets'];
    },
    organizations() {
      return this.$store.getters['staff/organizations'];
    },
    regions() {
      let regions=[];
      Object.entries(region).forEach((value)=>{
        regions.push({id: value[1], name:value[0]});
      })
      return regions;
    },
    walkinServiceTypes() {
      return this.$store.getters['staff/walkinServiceTypes'];
    },
    counters() {
      return this.$store.getters['staff/counters'];
    },
    staffPositions() {
      return this.$store.getters['staff/staffPositions'];
    },
    password() {
      return (
        'I' +
        this.staff.primeUser.userName.toLowerCase() +
        this.staff.staffId +
        '.'
      );
    },
    activatedByPosition() {
      return this.staff.staffPosition.name == 'Salesperson' ||
        this.staff.staffPosition.name == 'Technician'
        ? true
        : false;
    },
    totalCounters() {
      return this.outlet.counters ? this.outlet.counters.length : 0;
    },
    countersOptionFilteredForStaff() {
      return this.counters.filter((x) => {
        return (
          (x.outletId == this.staff.outlet.id ? true : false) &&
          (x.counterToCounterGroupAdjustmentLogs &&
          x.counterToCounterGroupAdjustmentLogs.length > 0
            ? true
            : false)
        );
      });
      // && (x.counterToStaffAdjustmentLogs &&
      //     x.counterToStaffAdjustmentLogs.length > 0
      //     ? false
      //     : true)
    },
    countersOptionFilteredForCounterGroup() {
      var temp = this.counters.filter((x) => {
        return (
          (x.outletId == this.counterGroup.outlet.id ? true : false) &&
          (x.counterToCounterGroupAdjustmentLogs &&
          x.counterToCounterGroupAdjustmentLogs.length > 0
            ? x.counterToCounterGroupAdjustmentLogs.find((y) => {
                return y.counterGroupId == this.counterGroup.id;
              })
              ? true
              : false
            : true)
        );
      });
      return temp;
    },
    walkinServiceTypesOptionFilteredForCounterGroup() {
      return this.walkinServiceTypes;
      // return this.walkinServiceTypes.filter((x) => {
      //   // Because of this, can't do paging for counter groups;
      //   return this.counterGroups.find((y) => {
      //     return (
      //       (y.walkinServiceTypes.find((z) => {
      //         return z.id == x.id;
      //       })
      //         ? (y.outletId == this.counterGroup.outletId?false:true)
      //         : false)
      //     );
      //   })
      //     ? false
      //     : true;
      // });
    },
    searchFilterbyOutletOptions() {
      var filterOptions = [];
      this.outlets.forEach((x) => {
        filterOptions.unshift({ id: x.id, name: x.name });
      });
      filterOptions.unshift({ id: 0, name: 'All' });
      return filterOptions;
    },
  },
  methods: {
    // searchFilterbyOutletName(value) {
    //   let filterString = "";
    //   switch (value) {
    //     case 1:
    //       filterString = "All";
    //       break;
    //     case 2:
    //       filterString = "No Action Yet";
    //       break;
    //     case 3:
    //       filterString = "In Progress";
    //       break;
    //     case 4:
    //       filterString = "Completed";
    //       break;
    //     default:
    //       filterString = "-";
    //   }
    //   return filterString;
    // },
    hasNoCounter() {
      this.staff.hasCounter = false;
      this.staff.counter = {};
    },
    loadLazyData() {
      this.loadingGetStaffs = true;
      this.$store
        .dispatch('staff/getStaffs', {
          lazyParams: this.lazyParams,
          searchFilterbyOutlet: this.searchFilterbyOutlet,
          searchFilterbyContact: this.searchFilterbyContact,
        })
        .then(() => {
          this.loadingGetStaffs = false;
        });
    },
    loadLazyDataCounterGroups() {
      this.loadingGetCounterGroups = true;
      
      this.lazyParamsCounterGroups.filterGroupbyOutletId= this.searchFilterGroupbyOutlet.id,
      this.$store
        .dispatch('staff/getCounterGroups', {
          lazyParams: this.lazyParamsCounterGroups,
        })
        .then(() => {
          this.loadingGetCounterGroups = false;
        });
    },
    onPage(event) {
      this.lazyParams = event;
      this.loadLazyData();
    },
    onSort(event) {
      this.lazyParams = event;
      this.loadLazyData();
    },
    onPageCounterGroups(event) {
      this.lazyParamsCounterGroups = event;
      this.loadLazyDataCounterGroups();
    },
    onSortCounterGroups(event) {
      this.lazyParamsCounterGroups = event;
      this.loadLazyDataCounterGroups();
    },
    dateDisplayFormat(createdAt) {
      var date = new Date(createdAt);
      var dateString;
      // var date=new Date(createdAt.substring(0,4),createdAt.substring(5,7),createdAt.substring(8,10));
      if (date.getFullYear() == 1) {
        dateString = 'Since Early Stage';
      } else {
        dateString =
          date.getDate().toString() +
          '/' +
          (date.getMonth() + 1).toString() +
          '/' +
          date.getFullYear().toString();
      }
      return dateString;
    },
    hasWhiteSpace(s) {
      return s.indexOf(' ') >= 0;
    },
    setCounterDetails() {
      if (this.staff.hasCounter == false) {
        this.staff.counter = {};
        // this.staff.counterGroup = {};
        // this.staff.counterDetail = {};

        // (this.staff.hasCounter == true && this.staff.counterDetail == {}) ||
        // (this.staff.hasCounter == false && this.staff.counterDetail != {})
      }
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      return;
    },
    openNew() {
      this.staff = {};
      this.staff['staffPosition'] = {};
      // this.staff["counter"] = {};
      // this.staff["counterGroup"] = {};
      this.submitted = false;
      this.staffDialog = true;
      this.staff.primeUser = {};
      this.staff.primeUser['userName'] = '';
      this.staff.primeUser['firstName'] = '';
      this.staff.primeUser['lastName'] = '';
      this.staff['staffId'] = '';
      this.staff['counter'] = {};
      this.errorStaff = false;
    },
    openNewGroup() {
      this.counterGroup = {
        isActive: true,
        walkinServiceTypes: [],
        counters: [],
      };
      this.submittedGroup = false;
      this.counterGroupDialog = true;
      this.errorGroup = false;
    },
    openNewOutlet() {
      this.outlet = { isActive: true, isHQ: false, totalCounters: 0 };
      this.submittedOutlet = false;
      this.outletDialog = true;
      this.errorOutlet = false;
    },
    hideDialog() {
      this.staffDialog = false;
      this.submitted = false;
    },
    hideDialogGroup() {
      this.counterGroupDialog = false;
      this.submittedGroup = false;
    },
    hideDialogOutlet() {
      this.outletDialog = false;
      this.submittedOutlet = false;
    },
    saveStaff() {
      this.submitted = true;
      this.loadingPostStaff = true;
      // this.staff.counterGroup = this.staff.counterDetail.counterGroupDetail;
      if (
        this.staff.staffId == '' ||
        this.staff.primeUser.userName == '' ||
        !this.staff.primeUser.userName ||
        this.hasWhiteSpace(this.staff.primeUser.userName) ||
        this.staff.primeUser.firstName == null ||
        this.staff.primeUser.lastName == null ||
        this.staff.primeUser.phone == null ||
        this.staff.primeUser.email == null ||
        !this.staff.primeUser.email.includes('@') ||
        !this.staff.primeUser.email.includes('.com') ||
        this.staff.staffPosition == {} ||
        this.password == null ||
        // (this.staff.hasCounter == true &&
        //   (this.staff.counterDetail.number == null ||
        //     this.staff.counterGroup.name == null)) ||
        // (this.staff.hasCounter == false &&
        //   (this.staff.counterDetail.number != null ||
        //     this.staff.counterGroup.name != null))
        (this.staff.hasCounter == true && this.staff.counter.number == null)
      ) {
        this.loadingPostStaff = false;

        console.log('Not Submitted');
        this.errorStaff = true;
        this.error = 'Please fill up all fields!';
      } else {
        if (this.staff.primeUser.userName.trim()) {
          var postStaff;
          if (this.staff.id) {
            this.staffs[this.findIndexById(this.staff.id, this.staffs)] =
              this.staff;
            this.$toast.add({
              severity: 'success',
              summary: 'Successful',
              detail: 'Staff Updated',
              life: 3000,
            });

            this.staff.primeUser.password = this.password;
            this.staff.primeUser.userName =
              this.staff.primeUser.userName.toLowerCase();

            postStaff = this.staff;

            this.$store
              .dispatch('staff/updateStaff', {
                staff: postStaff,
              })
              .then(() => {
                this.loadingPostStaff = false;
                this.submitted = false;
              })
              .catch((error) => {
                console.log(error);
                this.loadingPostStaff = false;
                this.errorStaff = true;
                this.error =
                  'Something is not right. Please refresh the page and try again.';
              });
          } else {
            this.staff.id = this.createId();
            this.$toast.add({
              severity: 'success',
              summary: 'Successful',
              detail: 'Staff Created',
              life: 3000,
            });
            Object.defineProperty(this.staff, 'id', { configurable: true });
            delete this.staff.id;
            this.staff.primeUser.password = this.password;
            this.staff.primeUser.userName =
              this.staff.primeUser.userName.toLowerCase();
            postStaff = this.staff;

            var postRegAuth = {
              userName: postStaff.primeUser.userName,
              email: postStaff.primeUser.email,
              password: postStaff.primeUser.password,
            };
            this.$store
              .dispatch('staff/postStaff', {
                staff: postStaff,
                regAuth: postRegAuth,
              })
              .then(() => {
                this.loadingPostStaff = false;
                this.submitted = false;
              })
              .catch((error) => {
                console.log(error);
                this.loadingPostStaff = false;
                this.errorStaff = true;
                this.error =
                  'Something is not right. Please refresh the page and try again.';
              });
          }

          this.staffDialog = false;
          this.staff = {};
        }
      }
    },
    saveGroup() {
      this.submittedGroup = true;
      this.loadingPostGroup = true;
      if (
        this.counterGroup.name == '' ||
        !this.counterGroup.name ||
        this.counterGroup.outlet == {} ||
        this.counterGroup.walkinServiceTypes.length == 0 ||
        this.counterGroup.counters.length == 0 ||
        this.counterGroup.isActive == null
      ) {
        this.loadingPostGroup = false;
        console.log('Not Submitted');
        this.errorGroup = true;
        this.error = 'Please fill up all fields!';
      } else {
        if (this.counterGroup.name.trim()) {
          if (this.counterGroup.id) {
            this.counterGroups[
              this.findIndexById(this.counterGroup.id, this.counterGroups)
            ] = this.counterGroup;
            this.$toast.add({
              severity: 'success',
              summary: 'Successful',
              detail: 'Group Updated',
              life: 3000,
            });
            this.$store
              .dispatch('staff/updateCounterGroup', {
                counterGroup: this.counterGroup,
                lazyParamsCounterGroups: this.lazyParamsCounterGroups,
              })
              .then(() => {
                this.loadingPostGroup = false;
                this.submittedGroup = false;
                this.errorGroup = false;

                this.loadingGetStaffs = true;
                this.$store
                  .dispatch('staff/getStaffs', { lazyParams: this.lazyParams })
                  .then(() => {
                    this.loadingGetStaffs = false;
                  });
              })
              .catch((error) => {
                console.log(error);
                this.loadingPostGroup = false;
                this.errorGroup = true;
                this.error =
                  'Something is not right. Please refresh the page and try again.';
              });
          } else {
            this.counterGroup.id = this.createId();
            this.$toast.add({
              severity: 'success',
              summary: 'Successful',
              detail: 'Group Created',
              life: 3000,
            });
            Object.defineProperty(this.counterGroup, 'id', {
              configurable: true,
            });
            delete this.counterGroup.id;

            this.$store
              .dispatch('staff/postCounterGroup', {
                counterGroup: this.counterGroup,
              })
              .then(() => {
                this.loadingPostGroup = false;
                this.submittedGroup = false;
                this.errorGroup = false;
              })
              .catch((error) => {
                console.log(error);
                this.loadingPostGroup = false;
                this.errorGroup = true;
                this.error =
                  'Something is not right. Please refresh the page and try again.';
              });
          }

          this.counterGroupDialog = false;
          this.counterGroup = {};
        }
      }
    },
    saveOutlet() {
      this.submittedOutlet = true;
      this.loadingPostOutlet = true;
      if (
        this.outlet.name == '' ||
        !this.outlet.name ||
        this.outlet.totalCounters == null ||
        this.outlet.isActive == null ||
        this.outlet.isHQ == null ||
        this.outlet.organization == null||
        this.outlet.region == null
      ) {
        this.loadingPostOutlet = false;
        console.log('Not Submitted');
        this.errorOutlet = true;
        this.error = 'Please fill up all fields!';
      } else {
        this.outlet.regionId=this.outlet.region.id;
        if (this.outlet.name.trim()) {
          if (this.outlet.id) {
            this.outlets[this.findIndexById(this.outlet.id, this.outlets)] =
              this.outlet;
            this.$toast.add({
              severity: 'success',
              summary: 'Successful',
              detail: 'Outlet Updated',
              life: 3000,
            });
            this.$store
              .dispatch('staff/updateOutlet', {
                outlet: this.outlet,
                lazyParamsCounterGroups: this.lazyParamsCounterGroups,
              })
              .then(() => {
                this.loadingPostOutlet = false;
                this.submittedOutlet = false;
                this.errorOutlet = false;
                this.loadingGetStaffs = true;
                this.$store
                  .dispatch('staff/getStaffs', { lazyParams: this.lazyParams })
                  .then(() => {
                    this.loadingGetStaffs = false;
                  });
              })
              .catch((error) => {
                console.log(error);
                this.loadingPostOutlet = false;
                this.errorOutlet = true;
                this.error =
                  'Something is not right. Please refresh the page and try again.';
              });
          } else {
            this.outlet.id = this.createId();
            this.$toast.add({
              severity: 'success',
              summary: 'Successful',
              detail: 'Outlet Created',
              life: 3000,
            });
            Object.defineProperty(this.outlet, 'id', { configurable: true });
            delete this.outlet.id;

            this.$store
              .dispatch('staff/postOutlet', {
                outlet: this.outlet,
              })
              .then(() => {
                this.loadingPostOutlet = false;
                this.submittedOutlet = false;
                this.errorOutlet = false;
              })
              .catch((error) => {
                console.log(error);
                this.loadingPostOutlet = false;
                this.errorOutlet = true;
                this.error =
                  'Something is not right. Please refresh the page and try again.';
              });
          }

          this.outletDialog = false;
          this.outlet = {};
        }
      }
    },
    editStaff(staff) {
      this.staff = { ...staff };
      this.staffDialog = true;
      this.errorStaff = false;
      this.submitted = false;
    },
    editGroup(counterGroup) {
      this.counterGroup = { ...counterGroup };
      this.counterGroupDialog = true;
      this.errorGroup = false;
      this.submittedGroup = false;
    },
    editOutlet(outlet) {
      this.outlet = { ...outlet };
      this.outletDialog = true;
      this.errorOutlet = false;
      this.submittedOutlet = false;
    },
    confirmDeleteStaff(staff) {
      this.staff = staff;
      this.deleteStaffDialog = true;
    },
    deleteStaff() {
      this.staffs = this.staffs.filter((val) => val.id !== this.staff.id);
      this.deleteStaffDialog = false;
      this.staff = {};
      this.$toast.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Staff Deleted',
        life: 3000,
      });
    },
    deleteGroup() {},
    deleteOutlet() {},
    findIndexById(id, array) {
      let index = -1;
      for (let i = 0; i < array.length; i++) {
        if (array[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = '';
      var chars =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dtStaffs.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteStaffsDialog = true;
    },
    deleteSelectedStaffs() {
      this.staffs = this.staffs.filter(
        (val) => !this.selectedStaffs.includes(val),
      );
      this.deleteStaffsDialog = false;
      this.selectedStaffs = null;
      this.$toast.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Staffs Deleted',
        life: 3000,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.staff-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .staff-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.staff-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-instock {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-lowstock {
    background: #feedaf;
    color: #8a5340;
  }
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.p-invalid {
  color: red;
}

@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-responsive-demo {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }

        &:last-child {
          border-bottom: 1px solid var(--surface-d);
          text-align: center;
        }

        .p-rating {
          display: inline-block;
        }
      }
    }
  }
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 75px;
  height: 75px;
  animation: spin 2s linear infinite;
}
</style>
